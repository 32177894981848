import React, { useState, useEffect } from "react";
import { QrReader } from "react-qr-reader";

function App() {
  const [qrData, setQrData] = useState(null);
  const [statusMessage, setStatusMessage] = useState("");

  const handleScan = (data) => {
    if (data) {
      const uniqueId = data.split("?id=")[1]; // Assumes QR code contains URL with ?id=uniqueId
      setQrData(uniqueId);
      checkQRCodeStatus(uniqueId);
    }
  };

  const handleError = (err) => {
    console.error(err);
    setStatusMessage("Error reading QR code.");
  };

  const checkQRCodeStatus = async (uniqueId) => {
    try {
      const response = await fetch("https://qr.hoope.me/check_qr.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ unique_id: uniqueId }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        setStatusMessage(errorData.error || "Error checking QR code status.");
      } else {
        const data = await response.json();
        setStatusMessage(`QR Code is ${data.enabled ? "enabled" : "disabled"}`);
      }
    } catch (error) {
      console.error("Error fetching QR code status:", error);
      setStatusMessage("Failed to connect to the server.");
    }
  };

  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <h1>QR Code Checker</h1>
      <QrReader
        delay={300}
        facingMode={"environment"} // Use the back camera
        onResult={(result, error) => {
          if (result) {
            handleScan(result.text);
          }
          if (error) {
            handleError(error);
          }
        }}
        constraints={{ facingMode: "environment" }} // This explicitly sets the camera to use the back camera
        style={{ width: "300px" }}
      />
      <p>{qrData ? `Scanned QR Code ID: ${qrData}` : "No QR code scanned"}</p>
      <p>{statusMessage}</p>
    </div>
  );
}

export default App;
